import React, { useState, useEffect, useRef } from "react";
import { LayersControl, MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import {getConstructionInformation, saveConstructionCoordinates} from "./actions";

const { BaseLayer } = LayersControl;

const ConstructionMapView = (props) => {
  const [state, setState] = useState({
    zoom: 17,
    data: {},
    marking: false,
    cursor: 'pointer',
    constructionInfo: [],
    construction: null,
    coordinates: [38.06820389131554, -0.872540639659949],
  });

  const [polygons, setPolygons] = useState([]);
  const drawControlRef = useRef(null);

  useEffect(() => {
    refresh();
  }, [props]);

  const refresh = () => {
    const { constructionid } = props;
    constructionid && getConstructionInformation(constructionid, (r) => {
      const polygons = r.reduce((l, c) => {
        return [...l, JSON.parse(c.json)];
      }, []);
      setPolygons(polygons);
    });
  };

  const savePolygonToServer = (polygonGeoJSON) => {
    const json = polygonGeoJSON && JSON.stringify(polygonGeoJSON);
    saveConstructionCoordinates(json, props.constructionid, () => refresh());
  };

  function CenterMapOnPolygons({ polygons }) {
    const map = useMap();

    useEffect(() => {
      if (polygons.length > 0) {
        const bounds = L.geoJSON(polygons).getBounds();
        const center = bounds.getCenter();
        map.setView([center.lat, center.lng], state.zoom);
      }
    }, [polygons, map]);

    return null;
  }

  function DrawControl({ polygons }) {
    const map = useMap();
    const drawnItemsRef = useRef(new L.FeatureGroup());

    useEffect(() => {
      if (!drawControlRef.current) {
        map.addLayer(drawnItemsRef.current);

        drawControlRef.current = new L.Control.Draw({
          position: 'topright',
          draw: {
            polygon: true,
            polyline: false,
            rectangle: false,
            circle: false,
            marker: false,
            circlemarker: false,
          },
          edit: {
            featureGroup: drawnItemsRef.current,
            remove: true,
          },
        });

        map.addControl(drawControlRef.current);

        map.on(L.Draw.Event.CREATED, function (event) {
          const layer = event.layer;
          drawnItemsRef.current.addLayer(layer);
          const polygonGeoJSON = layer.toGeoJSON();
          props.constructionid && savePolygonToServer(polygonGeoJSON);
        });

        map.on(L.Draw.Event.EDITED, function (event) {
          const layers = event.layers;
          layers.eachLayer(function (layer) {
            const editedPolygonGeoJSON = layer.toGeoJSON();
            props.constructionid && savePolygonToServer(editedPolygonGeoJSON);
          });
        });

        // Evento de eliminación de un polígono
        map.on(L.Draw.Event.DELETED, function (event) {
          const layers = event.layers;
          layers.eachLayer(function (layer) {
            drawnItemsRef.current.removeLayer(layer);
          });
          props.constructionid && savePolygonToServer(null);
        });
      }

      if (polygons && polygons.length > 0) {
        drawnItemsRef.current.clearLayers();
        polygons.forEach((polygon) => {
          const geoJsonLayer = L.geoJSON(polygon, {
            onEachFeature: (feature, layer) => {
              drawnItemsRef.current.addLayer(layer);
            },
          });
          map.addLayer(geoJsonLayer);
        });
      }

      return () => {
        if (drawControlRef.current) {
          map.removeControl(drawControlRef.current);
          map.removeLayer(drawnItemsRef.current);
          drawControlRef.current = null;
        }
      };
    }, [map, polygons]);

    return null;
  }




  return (
      <Box>
        <Paper sx={{ ml: 15, mt: 5 }}>
          <MapContainer
              center={state.coordinates}
              zoom={state.zoom}
              style={{ marginTop: 10, height: '73vh', width: '100%', cursor: state.cursor }}
          >
            <LayersControl position="topright">
              <BaseLayer checked name="Relieve">
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </BaseLayer>
              <BaseLayer name="Satélite">
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Tiles &copy; Esri - Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                />
              </BaseLayer>
            </LayersControl>
            <CenterMapOnPolygons polygons={polygons} />
            <DrawControl key={'draw-control'} polygons={polygons} />
          </MapContainer>
        </Paper>
      </Box>
  );
};

export default ConstructionMapView;
