import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updatePurchasenotedetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";


const filterOrders = (records, callback) => {
    if (records.purchasenoteid) {
        const toSend = {...records.purchasenoteid?.purchaseorderid};
        doPost('purchaseorder/getPurchaseorderproducts', toSend, r => {
            let products = r.map(r => r.productid);
            callback && callback(products);
        })
    } else {
        callback && callback([])
    }
}

const ui = {
    fields:{
        //purchasenotedetailid : {name: 'purchasenotedetailid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
        //purchasenotedetail : {name: 'purchasenotedetail', label:'Descripción' , typeName: 'String', optional: true },
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true,  filterRecords: filterOrders, size: 5},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: true, size: 5, urlRecords: 'rest/warehouse/getActives'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true, size: 2},
    }
}

export default function EditPurchasenotedetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [purchasenotedetailid, setPurchasenotedetail] = React.useState(selected || {});
    const [productQuantity, setProductQuantity] = React.useState([]);
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) => {
        let filter = Object.keys(record).filter(key => key === 'quantity');
        if ((record.productid && !filter.length) || purchasenotedetailid?.productid?.productid !== record.productid?.productid) {
            let quantityRecord = productQuantity.filter(p => p.productid?.productid === record.productid?.productid);
            if (quantityRecord.length) {
                record.quantity = quantityRecord[0].quantity;
            }
        }
        setPurchasenotedetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if (purchasenotedetailid.purchasenoteid) {
            const toSend = {...purchasenotedetailid.purchasenoteid?.purchaseorderid};
            doPost('purchaseorder/getPurchaseorderproducts', toSend, r => {
                setProductQuantity(r);
            })
        }
    }, [props])


    const onSuccessClick = (e) => {
        e.preventDefault()
        updatePurchasenotedetail(purchasenotedetailid, () => setOpen(!open))
    };
    return(
        <Box sx={{m: 1, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <SingleRecordForm ui={ui} records={purchasenotedetailid} onChange={handledChange} />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 2, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
