import L from "leaflet";

export const iconTicks = {
  0: L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGSSURBVFhH7ZdtboQgEIYB2wN1+3EM9aBNqx6j7W4PVJXOy44GFEQt9tc+iREIzPAO4KAUEaqqelBKfWitFR5uFlLKHg/xUpblNzd7CTqBcXpdrrVVnELOxpnZvL69P9FriwNw4XEzHCVm9lKehdbRMEZwVI3GdoQnxujIXsgvLiaB7J25eHUCFfbOSQHZkxwdMRRShmnKSUmlPrlyCLCvdN9nXD8E2E+6DiFuTjZxc7IJhcTD5UOAfWQ7bw5IBez/S7hMPmmapqVcnfzzQneDNs/ze6OEHDyb1sTgkoH3mBnruu5S5hQseFEUJjqj0dQbwLbnXBhSqSEVHam446p7GFOpITvOGs+uPqSmpU67d9pUBZiFZjqLrfjGz5SAveeGzkVH58JRAbyLvPfchMZ5lYCtakIqQHC7blWz1D+oBJCaHxrsnZ3N8I3i6ozFg0cOzLcnRqzfohIQUxNTARaVgNgs16iNOsE/RtfJR65OCf7C2USdgCzTmou7iK7JgOcXY5UKsErJX1mtBFhqVqsQQohfaGHLn5f1o6IAAAAASUVORK5CYII=',
    iconSize:     [25, 45], // size of the icon
  }),
  1: L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMsSURBVFhHtZfNThNRFMfvvZ2qAekEQSMmxkQTQyKJJiaiiSK0fLnSJQvXvoA738EX8A3cuYMWikFMVNQIKyMLF40EIqFlKB9KZ+71f4dD6TifQPnNYs45Mz1n/vfeOXfKWQLapvrecJlq50p0MMbPMqY2FZcVJZy16uC7J3RbKJFFzEJuXjHVrYR9mkI+ULyG4ovWSLGHQj4Ci9x5/kr8GHldlaLWQqFYuDT+dOfHWudePpMUquMrYhayX6Swb5N7aIRML1jDxVvkuniKmPnBkkztXib3yAjn1LI1MnWJXCbozDKFgZlmFNAgT1emkP1A7kERxtUDspqC4k4vmXtFzHzuN5ZkouWcGK448pZdMzPZn0eBYffCCYDlXRDcSV0lPxLBUqz7zDU21NHLelquszSOJOj8WpKF9ylDsUBacKzkxsk74Mrbx6wi18kLRjjpDYHWgDYRTV+nZ9nXuXfuBlnh6PwcfUmRH0gKRyU3TZ6f88Vh9hdHFAdLOAQ9F1HEXdcIzH6kkhrbZWMLL8jzs8O2yQpG5xdcih3yQ/laWSTLy83Zp2SFg/zb3JzIlaRRi20nbdhG7mKi09xgkik2V/7OyrJCV8MRdrrEM/mBWZVy7lOs6eA9ee+2kszkQ6nQBtxoE0FKtTE0I9zVxW1j2Y02GeRd0me3iDTseX1uNjJd+6bP9SEyJ7KbKNZK7rHBhK9bo8V213YjAMPnVm0WaCf10fFuvxPZLahJ/PEQhrCNLWt0ut4TPW1FCTlH5rFAnvrWq/EtW6ipQk1sZw4Dc7GBuTDJdfE1SDzFRzKPBObCNxqBLyBajYVWE7mRBdG4ohrxKdHIlO0Z06Tgd5/I9BDaSqCmDDW+pwpD1NJr1qNiJ7keApVoDqsGiyVQhSZUicYcz62iNQQ+XSNQsQoVF8j1EapEgwKJ1OC+yBUZqUQDNctIcpFcH1CxAhVd5AYSqUSDAp/JDCTuuiZWiQZqlpCs/ldgH6j4BRWxW3eiIniPedtkv6O3OgrokKpi1yMvkkQ34Vnwt9EokePyvx9FQiV77H8L7O/dFI4l8Y0a7hg/G88nhm6eZCaEsX+M+iHGlxTncAAAAABJRU5ErkJggg==',
    iconSize:     [25, 45], // size of the icon
  }),
  2: L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGOSURBVFhHvZdbSsQwFIbbugOZFx8EwVdnCepKtOA6fHAlwuBKrEvQ50EYYV7EHdgxf3oae8lJTtJkPhiaA23+frm0nbLw8HF28aoON11lpbnaf95S2wobIuh8ChtmDVEBjTpcd1UQbypodmOjkIi75xhZmZCEAT0mqNJlR8oAYPrTIWSRnL7fMsMwTWkQcqAiG8M5EdFW3v07Qxxy+lDr3/pra9pSRMPVnpTFerel6p/388ui+m2p4hGZrOp7ao1Z1XfUchM8JzGIQr43L9SKQxSCcd8/PlHVgfrneUOVm6B9opdviSV8UMHy7RU0J1WLztugAIAQvDty0hxldelnRM7nl3qnlL1JriHT/Q7fjMltYIHjcE5S25j+jAlIadNbgOnqSmUz6mdkAlLYDC2AbZ8stZldPzMBS2ymFoDb8bE21uusJiDGxmYBOBMQasOez5qAEBvOArhMgNTGeZ7TBEhsXBbAZwJ8Nl5brwlQNtxHeaMsnP8XgcRkMSITYLERWYCjmAQBG6w2shJSFH8e+YWLpgPDOwAAAABJRU5ErkJggg==',
    iconSize:     [25, 45], // size of the icon
  })
};
