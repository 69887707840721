import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import ConstructionMapView from "./MapView/ConstructionMapView";

export const ConstructionMapDialog = (props) => {

    useEffect(() => {

    }, [props]);

    return (
        <Box>
            <ConstructionMapView constructionid={props.constructionid}/>
        </Box>
    );
};

export default ConstructionMapView;
