import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "../../../../View/Form/GridTable";
import {doPost} from "../../../../Utils/Restclient/NetworkActions";
import Typography from "@mui/material/Typography";

const uiInfo = {
    fields: {
        reference : {name: 'reference', label: 'Referencia',typeName:'String', editable: false, flex: 0.75},
        customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', editable: false},
        date : {name: 'date', label: 'Fecha',typeName:'Date', editable: false},
        shippingdate : {name: 'shippingdate', label: 'Fecha salida',typeName:'Date', editable: false},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false, flex: 0.45},
        launched : {name: 'launched', label: 'Lanzado',typeName:'Number', editable: false, flex: 0.45},
        done : {name: 'done', label: 'Hecho',typeName:'Number', editable: false, flex: 0.45},
        orderremarks : {name: 'orderremarks', label: 'Observaciones pedido',typeName:'String', editable: false, flex: 2},
        plannedremarks : {name: 'plannedremarks', label: 'Observaciones producción',typeName:'String', editable: false, flex: 2},
    }
}

export default function SaleorderDialog(props) {
    const {data} = props;
    const [records, setRecords] = React.useState([]);

    useEffect(() => {
            refresh()
    }, [data])

    const refresh = () => {
        doPost("plannedproduction/plannedInfo", data, result => {
            if (result) {
                setRecords(result)
            }
        })
    }

    return(
    <Box sx={{m: 2, alignItems: 'center'}}>
        <Box sx={{m: 2}}>
            <GridTable ui={uiInfo} records={records}/>
        </Box>
    </Box>
    )
}
