import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deletePurchasenotedetail,
    getDetails,
    updatePurchasenotedetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditPurchasenotedetailDialog from "./EditPurchasenotedetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields: {
        //purchasenotedetailid : {name: 'purchasenotedetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product' },
        batchid : {name: 'batchid', label: 'Lote',typeName:'es.rbm.model.jpa.Batch', editable: false},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
    },
    sort: {
        field: 'purchasenotedetailid',
        sort: 'desc'
    }
}

export default function DetailsPurchasenoteDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [purchasenotedetails, setPurchasenotedetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);

    useEffect(() =>{
        if(selected && selected.purchasenoteid){
            getDetails(selected, result =>{
                setPurchasenotedetails(result);
            });
        }
    }, [openEditDetail, selected, refresh])

    const onDelete = (purchasenotedetailid) => {
        deletePurchasenotedetail(purchasenotedetailid, () => setRefresh(!refresh))
    }

    const onEdit = (purchasenotedetailid) => {
        setSelectedDetail(purchasenotedetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = purchasenotedetails.filter(r => r.purchasenotedetailid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updatePurchasenotedetail(record[0])
        } else{
            updatePurchasenotedetail(e, () => setRefresh(!refresh))
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <CustomDialog fullWidth title={selectedDetail ? 'Editar detalle' : 'Nuevo detalle'}
                          maxWidth={"md"} open={openEditDetail} onClose={() => setOpenEditdetail(false)}>
                <EditPurchasenotedetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </CustomDialog>
            <Button size={'small'} variant="contained" onClick={() => onEdit({purchasenoteid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.purchasenoteid}>
               Añadir detalle
            </Button>
            <div>
                <GridTable ui={ui} onDelete={onDelete} onEdit={onEdit}
                           records={purchasenotedetails} rowId={'purchasenotedetailid'}
                           onChange={onChange}
                />
            </div>
        </Box>
    )
}
