import * as React from 'react';
import {makeStyles} from "@mui/styles";
import TableRow from "@mui/material/TableRow";
import {
    Collapse,
    Dialog,
    Divider, FormControl,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TableCell,
    tableCellClasses
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Paper from "@mui/material/Paper";
import {ProgressBar} from "../../../../View/ProgressBar";
import {useCallback, useEffect} from "react";
import {getPlanification} from "./PlannedproductionController";
import Typography from "@mui/material/Typography";
import {PlannedproductionCell} from "./PlannedproductionCell";
import {SimpleDialog} from "./SimpleDialog";
import AssignmentIcon from '@mui/icons-material/Assignment';
import DetailsIcon from '@mui/icons-material/Details';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AddPlannedproductionDialog from "./AddPlannedproductionDialog";
import {styled} from "@mui/material/styles";
import ExecutionsDialog from "./ExecutionsDialog";
import {converterRecord} from "../../../../View/Form/SingleSelectForm";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {maxHeightViews} from "../../../../Utils/Constant";
import {ProgressBarV2} from "../../../../View/ProgressBarv2";
import {he} from "date-fns/locale";
import SaleorderDialog from "./SaleorderDialog";
import {OPERATORS} from "../../../../Utils/Persistence/PersistenceConstant";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomDialog from "../../../../View/CustomDialog";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset'
        },
    },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledHeaderTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#000000',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        color: '#ffffff',
    },
}));

function Row(props) {
    const { row, onCellClick } = props;
    const [open, setOpen] = React.useState(false);
    const [segments, setSegments] = React.useState([]);
    const classes = useRowStyles();

    const getProductName = record => {
        const info = converterRecord(record, {typeName: 'es.rbm.model.jpa.Product'});
        return info.label;
    }

    const getCustomerName = record => {
        const info = converterRecord(record, {typeName: 'es.rbm.model.jpa.Customer'});
        return info.label;
    }

    useEffect(() => {
        if (row) {
            const launched = row.launchedpercent;
            const done = row.donepercent;
            const pending = 100 - launched;
            const newPercents = [
                { value: 100, color: '#ffffff', label: 'Pendiente (' + pending.toFixed(2) + '%)' },
                { value: launched, color: '#f4f136', label: 'Lanzado (' + launched.toFixed(2) + '%)' },
                { value: done, color: '#289e49', printPercent: true, label: 'Producido (' + done.toFixed(2) + '%)'},
               ];
            setSegments(newPercents);
        }
    }, [row])

    return (
        <React.Fragment>
            <StyledTableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{height: 5}}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{row.title}</strong>
                </TableCell>
                <TableCell align="center">{row.totalquantity}</TableCell>
                <TableCell align="center"> <ProgressBarV2 bgcolor={'white'} segments={segments}/></TableCell>
                <TableCell align="center"><Brightness1Icon color={row.status}/></TableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {
                                            row?.header?.map((h, i) => {
                                                const bold = h.bold;
                                                const bgcolor = h.bgcolor;
                                                const sx = {}
                                                if (bold) {
                                                    sx.fontWeight = 'bold';
                                                }
                                                if (bgcolor) {
                                                    sx.bgcolor = h.bgcolor;
                                                }
                                                if (i === 0) {
                                                    sx.width = 400;
                                                }
                                                return <TableCell key={i} sx={{...sx}} align={'center'}>{h.header}</TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.details?.map((detailRow, i) => (
                                        <TableRow key={i}>
                                            <TableCell align={'left'} component="th" scope="row">{getProductName(detailRow.productid)}</TableCell>
                                            {Array.from({ length: 7 }, (_, index) => {
                                                const day = index + 1;
                                                const label = 'day' + day;
                                                const detail = detailRow[label];
                                                const header = row.header[day];
                                                const bold = header.bold;
                                                const bgcolor = header.bgcolor;
                                                const sx = {}
                                                if (bold) {
                                                    sx.fontWeight = 'bold';
                                                }
                                                if (bgcolor) {
                                                    sx.bgcolor = header.bgcolor;
                                                }
                                                return (
                                                    <TableCell sx={{...sx}} key={label} align={'center'}>
                                                        {detail && (
                                                            <PlannedproductionCell
                                                                bgcolor={bgcolor}
                                                                onCellClick={onCellClick}
                                                                data={detail}
                                                            />
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const ui = {
    fields:{
        customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', editable: true, size: 3},
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, size: 3,
            criteriaFilter: {
                entity : { name: 'product'},
                fields: {
                    ['producttypeid.saleproduct'] : {name: 'producttypeid.saleproduct', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                    active : {name: 'active', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                },
            }},
        week : {name: 'week', label:'Semana' , typeName: 'Number', editable: true, size: 1},
        year : {name: 'year', label:'Año' , typeName: 'Number', editable: true, size: 1},
        pending : {name: 'pending', label:'Solo pendiente' , typeName: 'Boolean', editable: true, size: 2},
    }
}

const CustomRadioGroup = styled(RadioGroup)({
    fontSize: '1'
});

export default function PlannedproductionView() {
    const moment = require('moment');
    const [records, setRecords] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [loadComponent, setLoadComponent] = React.useState(null);
    const [componentTitle, setComponentTitle] = React.useState('');
    const [data, setData] = React.useState(null);
    const [radioValue, setRadioValue] = React.useState('customer');
    const [filters, setFilters] = React.useState({week: moment().isoWeek(), year: moment().year()});

    const handleClose = (value) => {
        setOpen(false);
        setComponentTitle(value?.title || '')
        setLoadComponent(value?.component)
    };

    useEffect(() => {
        if (!loadComponent) {
            refresh();
        }
    }, [loadComponent])

    useEffect(() => {
        if (!loadComponent && records.length) {
            refresh();
        }
    }, [radioValue])

    const refresh = (filter) => {
        const filtersToApply = filter ? filter : filters
        filtersToApply.mode = radioValue;
        getPlanification( filtersToApply, results => {
            setRecords(results);
        })
    }

    const onCellClick = (data) => {
        setOpen(true);
        setData(data);
    }

    const handledChange = (record) =>{
        setFilters({...record});
        refresh(record);
    }

    const handleRadioChange = (event) =>{
        setRadioValue(event.target.value);
    }

    const options = [
        {key: 1, label: 'Asignar pedido', title: 'Asignar pedido' , icon: <AssignmentIcon />, component: <AddPlannedproductionDialog data={data} setOpen={setLoadComponent} open={loadComponent}/>},
        {key: 2, label: 'Ver/Editar lanzamientos y ejecuciones', title: 'Lanzamientos y ejecuciones', icon: <ManageHistoryIcon />, component: <ExecutionsDialog data={data} setOpen={setLoadComponent} open={loadComponent}/>},
        {key: 3, label: 'Ver pedidos', icon: <DetailsIcon />, title: 'Pedidos', component: <SaleorderDialog data={data} setOpen={setLoadComponent} open={loadComponent}/>},
    ];

    const label = radioValue === 'customer' ? 'Cliente' : (radioValue === 'product' ? 'Producto' : '');
    return (
        <Box sx={{flexGrow: 1,  mt: 1, maxHeight: '86vh'}} overflow={'auto'}>
            <CustomDialog fullWidth maxWidth="xl" title={componentTitle}
                          open={loadComponent} onClose={() => setLoadComponent(null)}> {loadComponent} </CustomDialog>
            <SimpleDialog selectedValue={null} open={open} onClose={handleClose} options={options}/>
            <Paper sx={{ p:1.5, pb: 1}}>
                <Grid container>
                    <Grid md={11} item sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                        <SingleRecordForm  ui={ui} records={filters} onChange={handledChange} disableAutoFocus />
                    </Grid>
                    <Grid md={1} item sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="small">
                            <RefreshIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item md={12} align={'center'} >
                        <FormControl>
                            <CustomRadioGroup row aria-labelledby="planned-buttons-group-label" name="row-radio-buttons-group"
                                              value={radioValue} onChange={handleRadioChange} sx={{ gap: 0, mt: 1}}>
                                <FormControlLabel value="customer" control={<Radio size={'small'}/>} label="Por cliente" />
                                <FormControlLabel value="product" control={<Radio size={'small'}/>} label="Por producto" />
                                <FormControlLabel value="grouped" control={<Radio size={'small'}/>} label="Agrupado" />
                            </CustomRadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <StyledHeaderTableRow>
                            <TableCell />
                            <TableCell sx={{alignItems: 'center'}}><strong>{label}</strong></TableCell>
                            <TableCell align="center"><strong>Cantidad</strong></TableCell>
                            <TableCell align="center"><strong>% Fabricado</strong></TableCell>
                            <TableCell align="center"><strong>Estado</strong></TableCell>
                        </StyledHeaderTableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((row, i) => (
                            <Row key={i} onCellClick={onCellClick} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
