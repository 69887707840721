import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateInventorydetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";

const ui = {
    fields:{
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, flex:3},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch', editable: true, flex:3, optional: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}

export default function EditInventorydetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [inventorydetailid, setInventorydetail] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) => {
        setInventorydetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{

    }, [props])

    const onSuccessClick = (e) => {
        updateInventorydetail(inventorydetailid, () => setOpen(!open))
    };

    return(
        <Box sx={{p: 1, m: 2}}>
            <SingleRecordForm ui={ui} records={inventorydetailid} onChange={handledChange} />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
