import * as React from 'react';
import Box from '@mui/material/Box';
import {useEffect} from "react";
import SingleRecordForm from "../SingleRecordForm";
import SuccessButton from "../../Button/SuccessButton";
import Paper from "@mui/material/Paper";
import {doPost} from "../../../Utils/Restclient/NetworkActions";
import {success} from "../../../Utils/Notification/notifications";
import Typography from "@mui/material/Typography";
import DragDropFiles from "../../DragDropFiles/DragDropFiles";
import NegativeButton from "../../Button/NegativeButton";
import Dialog from "@mui/material/Dialog";
import {IconButton, LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import warning from "react-redux/es/utils/warning";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const uiTo = {
    fields:{
        to : {name: 'to', label:'Enviar a' , typeName: 'String', size: 12},
    }
}

const uiSubject = {
    fields:{
        subject : {name: 'subject', label:'Asunto' , typeName: 'String', size: 12, optional: true},
    }
}

const uiCopy = {
    fields:{
        cc : {name: 'cc', label:'CC' , typeName: 'String', size: 12, optional: true},
        cco : {name: 'cco', label:'CCO' , typeName: 'String', size: 12, optional: true},
    }
}
const uiBody = {
    fields:{
        body : {name: 'body', label:'Cuerpo' , typeName: 'String', size: 12, multiline: true, rows: 15, optional: true}
    }
}

export default function SendMailView(props) {
    const [records, setRecords] = React.useState({});
    const {closeDialog, sx} = props;
    const [isCompleteHeader, setCompleteHeader] = React.useState(false);
    const [isCompleteBody, setCompleteBody] = React.useState(false);
    const [attachmentsFiles, setAttachmentsFiles] = React.useState([]);
    const [selectedAttachment, setSelectedAttachment] = React.useState(null);
    const [openImportData, setOpenImportData] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [openCopy, setOpenCopy] = React.useState(false);

    const handledChangeHeader = (record, complete) => {
        setRecords({...record});
        setCompleteHeader(complete);
    }

    const handledChangeBody = (record, complete) => {
        setRecords({...record});
        setCompleteBody(complete);
    }

    useEffect(() =>{
        if(props.records){
            setRecords(props.records)
        }
    }, [])


    const sendMail = () => {
        let attachmentToSend = attachmentsFiles.map(file => {
            return {name: file.target.name, content: file.target.value}});
        const toSend = {...records, attachments: attachmentToSend}
        setSending(true);
        warning("Enviando mensaje...");
        doPost("mail/sendMail", toSend, () =>{
            success("Mensaje enviado");
            setSending(false);
            setRecords({});
            setAttachmentsFiles([]);
            if(closeDialog){
                closeDialog(false);
            }
        }, false)
    }

    const handledFileAdd = (file1) =>{
        setSelectedAttachment(file1);
    }

    const addAttachment = () =>{
        let newAttachment = attachmentsFiles ? [...attachmentsFiles, selectedAttachment] : [selectedAttachment];
        setAttachmentsFiles(newAttachment)
        setSelectedAttachment(null)
        setOpenImportData(!openImportData);
    }

    const deleteAttachment = (at) =>{
        let newAttachment = attachmentsFiles.filter(a => a !== at);
        setAttachmentsFiles(newAttachment)
    }

    return (
        <Paper sx={{...sx}}>
            <Grid container sx={{ width: '100%' }}>
                {/*<Grid container md={12}>
                    <Grid item sx={{m:2, mb: 0}} md={12} align = "right">
                        {closeDialog && <Button size={'small'} variant="contained" color="error" onClick={() => {closeDialog(false)}} >
                            X
                        </Button>}
                    </Grid>
                </Grid>*/}
                <Grid item md={12}>
                    <Dialog open={openImportData} onClose={() => {setSelectedAttachment(null);setOpenImportData(false)}}>
                        <Box sx={{m: 5}}>
                            <Typography sx={{mb:5}} variant="h5" align="center" color="text.secondary" paragraph>
                                Seleccione el archivo a importar
                            </Typography>
                            <DragDropFiles convertToBase64={true} onLoadFile={handledFileAdd} name={'file'} file={selectedAttachment}/>
                            <SuccessButton text={'ADJUNTAR'} fullWidth sx={{mb: 1, mt: 5}} onClick={addAttachment}/>
                        </Box>
                    </Dialog>
                    <Box sx={{height: '80%', padding: 3}}>
                        <Grid container justifyContent="flex-end" sx={{mb:1}}>
                            <Grid item sx={{mt:2}} md={11}>
                                {sending && <LinearProgress/>}
                            </Grid>
                            <Grid item md={1}>
                                <IconButton onClick={() => setOpenImportData(!openImportData)} color={'primary'} variant="contained" size="small">
                                    <AttachFileIcon /> Adjuntar
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={11.7}>
                                <SingleRecordForm ui={uiTo} records={records} onChange={handledChangeHeader} disableAutoFocus />
                            </Grid>
                            <Grid item md={0.3} justifyContent="end">
                                <IconButton onClick={() => setOpenCopy(!openCopy)} color={'primary'} variant="contained" size="small">
                                    {openCopy ? <KeyboardArrowUpIcon fontSize={'large'}/> : <KeyboardArrowDownIcon fontSize={'large'}/> }
                                </IconButton>
                            </Grid>
                            {openCopy &&
                                <Grid item md={12} sx={{mt:2}}>
                                    <SingleRecordForm ui={uiCopy} records={records} onChange={handledChangeBody} disableAutoFocus />
                                </Grid>}
                            <Grid item md={12} sx={{mt:2}}>
                                <SingleRecordForm ui={uiSubject} records={records} onChange={handledChangeHeader} disableAutoFocus />
                            </Grid>
                        </Grid>
                        {attachmentsFiles && attachmentsFiles.length ?
                            attachmentsFiles.map((at, index) =>
                                <IconButton key={'attachment' + index} sx={{m: 0}} onClick={() => deleteAttachment(at)} color={'primary'} variant="contained" size="small">
                                    <TextSnippetIcon/> {at.target.name}
                                </IconButton> ) :
                            <Typography component="p" variant="h7" sx={{m: 2}}>
                                {'No hay archivos adjuntos'}
                            </Typography>}
                        <SingleRecordForm ui={uiBody} records={records} onChange={handledChangeBody} disableAutoFocus />
                        <SuccessButton text={'ENVIAR'} fullWidth sx={{mt: 3}} onClick={(e) => {e.preventDefault(); sendMail()}} disabled={(sending || (!isCompleteBody && !isCompleteHeader))}/>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}
