import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deleteAlusalenotedetail,
    getDetails,
    updateAlusalenotedetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";
import EditSalenotedetailDialog from "./EditAlusalenotedetailDialog";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields: {
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3,editable: false},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: false},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: false, urlRecords: 'rest/warehouse/getActives'},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch', editable: false},
        measure : {name: 'measure', label:'Medidas' , typeName: 'String', align: 'center', optional: true},
        reference : {name: 'reference', label:'Referencia' , typeName: 'String', align: 'center',  optional: true},
    },
}

export default function DetailsAlusalenoteDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [salenotedetails, setSalenotedetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);

    useEffect(() =>{
        if(selected && selected.salenoteid){
            getDetails(selected, result =>{
                setSalenotedetails(result);
            });
        }
    }, [openEditDetail, selected, refresh])

    const onDelete = (salenotedetailid) => {
        deleteAlusalenotedetail(salenotedetailid, () => setRefresh(!refresh))
    }

    const onEdit = (salenotedetailid) => {
        setSelectedDetail(salenotedetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = salenotedetails.filter(r => r.salenotedetailid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updateAlusalenotedetail(record[0])
        } else{
            updateAlusalenotedetail(e, () => setRefresh(!refresh))
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <CustomDialog fullWidth maxWidth="md" title={selectedDetail ? 'Editar detalle' : 'Nuevo detalle'}
                          open={openEditDetail} onClose={() => setOpenEditdetail(false)}>
                <EditSalenotedetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </CustomDialog>
            <Button size={'small'} variant="contained" onClick={() => onEdit({salenoteid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.salenoteid}>
               Añadir detalle
            </Button>
            <Box>
                <GridTable ui={ui} onDelete={onDelete} onEdit={onEdit}
                           records={salenotedetails} rowId={'salenotedetailid'}
                           onChange={onChange} actionFlex={0.4}
                />
            </Box>
        </Box>
    )
}
