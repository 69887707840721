import * as React from 'react';

import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "./GridTable";
import uiDefinition from "../../Utils/uiDefinition";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {filterRecordFromText} from "../../Utils/UtilsCommon";
import SingleRecordForm from "./SingleRecordForm";
import {OPERATORS} from "../../Utils/Persistence/PersistenceConstant";
import SuccessButton from "../Button/SuccessButton";
import {doGet, doPost} from "../../Utils/Restclient/NetworkActions";
import entityFilters from "../../Utils/entityFilters";

export default function SelectSelectEntity(props) {
    const {onSelected, onClose, refreshData, defaultRecords} = props;
    const [ui, setUI] = React.useState({fields: {}});
    const [records, setRecords] = React.useState([]);
    const [originalRecords, setOriginalRecords] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [filters, setFilters] = React.useState({});

    useEffect(() => {
        getUI(props);
    }, [defaultRecords])

    useEffect(() => {
        if (!filter || !filter.length) {
            setOriginalRecords(records)
        }
    }, [records])

    useEffect(() => {
        if (!filter || !filter.length) {
            setOriginalRecords(records)
        }
    }, [records])

    useEffect(() => {
        searchByCriteria()
    }, [filters])

    const handledChange = (v) => {
        onSelected(v.row);
        onClose();
    }

    const getUI = (props) => {
        const uiDefinitionElement = uiDefinition[props.typeName];
        if (uiDefinitionElement) {
            const descriptiveFields = uiDefinitionElement.descriptiveField.split('.').reduce((l, c, i) => {
                const newField = {[c] : {name: c, label: props.label, typeName: 'String', editable: false, align: 'center'}}
                    return {...l, ...newField}
            }, {[uiDefinitionElement.keyField] : {name: uiDefinitionElement.keyField, label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5, optional: true}});
            const ui  = {fields: {...descriptiveFields}}
            setUI(ui)
            if (refreshData) {
                refreshData(result => setRecords(result));
            }
        }
    }

    const onKeyDown = (params, event) => {
        if (event.key === 'Enter') {
            onSelected(params.row);
            onClose();
        }
    };

    const onChangeFilter = (var1) => {
        const value = var1.target.value;
        setFilter(value);
        if (value) {
            const columns = Object.keys(ui.fields);
            const filteredRecords = filterRecordFromText(value, originalRecords, columns);
            setRecords(filteredRecords);
        } else {
            setRecords(originalRecords);
        }
    };

    const searchByCriteria = () => {
        const keys = Object.keys(filters);
        const entityName = uiDefinition[props.typeName].entityName;
        const entityFilter = entityFilters[props.typeName];
        const itemsQuery = keys.map(f => {
            const field = entityFilter.fields[f];
            const timeFields = ['Date', 'Datetime']
            const operator = field.operator;
            const hours = new Date(filters[f]);
            hours.setHours(0,0,0,0);
            let newVar = {
                field: field.name.split("_")[0],
                operator: operator,
                value: timeFields.includes(field.typeName) ? (operator === OPERATORS.OPERATOR_BETWEEN ? hours.getTime() : new Date(filters[f]).getTime()) : filters[f],
            };

            if (operator === OPERATORS.OPERATOR_BETWEEN) {
                hours.setHours(23,59,59,59)
                newVar = {...newVar, value2: timeFields.includes(field.typeName) ? hours.getTime() : filters[f] }
            }
            return newVar
        }).filter(data => data.value !== null);
        const request = {
            clazz: entityName,
            items: [...itemsQuery]
        }

        if (request?.items?.length > 0) {
            doPost("rest/getByQuery", request, result => {
                if (result) {
                    setRecords(result);
                }
            }, true)
        } else {
            setRecords(defaultRecords)
        }
    }

    const handledFilterChange = (newFilter, complete) =>{
        const filterToSave = Object.keys(newFilter).reduce((l, c) => {
            return newFilter[c] ? {...l, [c] : newFilter[c]} : {...l};
        }, {});
        setFilters({...filterToSave});
    }

    return (
        <Box>
            <Grid container sx={{m: 1, width: '96%'}}>
                { entityFilters[props.typeName] ? <Grid item md={12}>
                    <Grid item md={12} overflow={'auto'} sx={{maxHeight: '35vh', pt: 1}}>
                        <SingleRecordForm  ui={entityFilters[props.typeName]} records={filters} onChange={handledFilterChange}/>
                    </Grid>
                </Grid> :
                <Grid item md={12}>
                    <Paper sx={{bgcolor: 'white'}}>
                        <TextField fullWidth autoFocus value={filter}
                                   label={'Buscar...'} onChange={onChangeFilter}/>
                    </Paper>
                </Grid>
                }
            </Grid>
            <GridTable ui={ui} onCellKeyDown={onKeyDown}
                               records={records} filterFocus={true}
                               onRowDoubleClick={handledChange} hideFooter
                               sx={{height:'63vh', px: 2, pb: 2}}/>
        </Box>
    )
}

SelectSelectEntity.propTypes = {
    defaultRecords: PropTypes.array
}
