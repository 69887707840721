import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import NewAlusaleorderHouseDialog from "./NewAlusaleorderHouseDialog";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import CustomDialog from "../../../../../../View/CustomDialog";

const filterHouse = (records, callback) => {
    const saleinvoiceid = records.saleinvoiceid;
    if(saleinvoiceid) {
        const filters = {
            entity : {name: 'salenote'},
            fields: {
                saleinvoiceid : {name: 'saleinvoiceid', operator: OPERATORS.OPERATOR_ISNULL},
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: saleinvoiceid.customerid},
            },
        }
        searchByCriteria(filters, result =>{
            callback && callback(result);
        });
    } else {
        callback && callback([])
    }
}

const defaultUI = {
    fields:{
        houseid : {name: 'houseid', label: 'Vivienda' ,typeName: 'es.rbm.model.jpa.House', flex: 4},
    }
}

export default function AlusaleorderHouseView(props) {
    const {alusaleorderid} = props;
    const [records, setRecords] = React.useState([]);
    const [ui, setUI] = React.useState(defaultUI);
    const [house, setHouse] = React.useState(null);
    const [openNewAlusaleorderHouse, setOpenNewAlusaleorderHouse] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newAlusaleorderHouse = () => {
        setOpenNewAlusaleorderHouse(true);
    }

    const onEdit = (houseid) => {
        setHouse(houseid);
        setOpenNewAlusaleorderHouse(!openNewAlusaleorderHouse)
    }

    const onDelete = (houseid) =>{
        deleteObject(houseid,{name:"saleorderhouse", keyField:'saleorderhouseid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.houseid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('house',toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })

    }

    useEffect(() =>{
        if (!openNewAlusaleorderHouse){
            const filters = {
                fields: {alusaleorderid : {name: 'alusaleorderid', operator: 'OPERATOR_EQUAL', value: alusaleorderid},},
                entity : { name: 'saleorderhouse'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setHouse(null);
            });
        }
    }, [refreshObjects, openNewAlusaleorderHouse, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <CustomDialog fullWidth title={house ? 'Editar vivienda' : 'Nueva vivienda'} onClose={() => setOpenNewAlusaleorderHouse(false)}
                              maxWidth={"sm"} open={openNewAlusaleorderHouse}>
                    <NewAlusaleorderHouseDialog houseid={house} records={records} alusaleorder={alusaleorderid} setOpen={setOpenNewAlusaleorderHouse} open={openNewAlusaleorderHouse}/>
                </CustomDialog>
                <Button size={'small'} variant="contained" onClick={newAlusaleorderHouse} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir vivienda
                </Button>
                <GridTable ui={ui} sx={{mt: 2}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'saleorderhouseid'}
                           onChange={onChange} toolBar
                />
            </Box>
        </Box>
    );
}
