import {OPERATORS} from "./Persistence/PersistenceConstant";

const entityFilters = {
     'ObjectRender' : {
        keyField: 'id',
        descriptiveField: 'value'
     },
    'es.rbm.model.jpa.Customer' : {
        fields: {
            customer : {name: 'customer', label: 'Cliente' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    'es.rbm.model.jpa.Construction' : {
         fields : {
             construction : {name: 'construction', label:'Nombre' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
             customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
         }
    },
    'es.rbm.model.jpa.Product' : {
        fields: {
            productid : {name: 'productid', label: 'Código' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            product : {name: 'product', label: 'Producto' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            producttypeid : {name: 'producttypeid',label: 'Tipo de producto', typeName: 'es.rbm.model.jpa.Producttype', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
     'es.rbm.model.jpa.Provider' : {
         fields: {
             provider : {name: 'provider', label: 'Proveedor' ,typeName: 'String', operator: 'OPERATOR_LIKE', optional: true},
             townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
             stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
             countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
         }
    },
    'es.rbm.model.jpa.Purchaseorder' : {
        fields: {
            purchaseorderid : {name: 'purchaseorderid', label: 'Nº pedido' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            providerid : {name: 'providerid', label: 'Proveedor' ,typeName: 'es.rbm.model.jpa.Provider', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            purchaseorderstatusid : {name: 'purchaseorderstatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Purchaseorderstatus', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
        }
    },
    'es.rbm.model.jpa.Town' : {
         fields: {
             town : {name: 'town', label:'Población' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
             stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_EQUAL, optional: true}
         }
    },
    'es.rbm.model.jpa.Vehicle' : {
        fields: {
            vehicle : {name: 'vehicle', label:'Vehículo', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            vehiclebrandid : {name: 'vehiclebrandid', label:'Marca' , typeName: 'es.rbm.model.jpa.Vehiclebrand', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            model : {name: 'model', label:'Modelo' , typeName: 'String', align: 'center', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            platenumber : {name: 'platenumber', label:'Matrícula' , typeName: 'String', align: 'center', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    'es.rbm.model.jpa.Worker' : {
        fields: {
            name : {name: 'name', label: 'Nombre' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            surname : {name: 'surname', label: 'Apellidos' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    'es.rbm.model.jpa.Workspace' : {
         fields : {
             workspaceid : {name: 'workspaceid', label:'Codigo' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true },
             workspace : {name: 'workspace', label:'Espacio de trabajo' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
             address : {name: 'address', label:'Dirección' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
             townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
         }
    },
}

export default entityFilters;
