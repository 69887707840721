import * as React from 'react';
import { PivotViewComponent, Inject, FieldList, CalculatedField, GroupingBar, Toolbar, PDFExport, ExcelExport, ConditionalFormatting } from '@syncfusion/ej2-react-pivotview';
import './olap.css';
import PropTypes from "prop-types";
import {doPost} from "../../Utils/Restclient/NetworkActions";
import {useEffect} from "react";
import {registerLicense} from "@syncfusion/ej2-base";

function OlapSample(props) {
    const {olapid, defaultDataSourceSettings, width, height, showFieldList, showGroupingBar, allowExcelExport, allowConditionalFormatting,
            allowPdfExport, showToolbar, enableFieldSearching, allowCalculatedField} = props;
    const [mount, setMount] = React.useState(false);
    const [dataSourceSettings, setDataSourceSettings] = React.useState(defaultDataSourceSettings);

    let pivotObj;
    let toolbarOptions = ['New', 'Save', 'SaveAs', 'Rename', 'Remove', 'Load',
        'Grid', 'Chart', 'MDX', 'Export', 'SubTotal', 'GrandTotal', 'ConditionalFormatting', 'FieldList'];

    registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Td0ViUH1ccnJRQmdY');

    useEffect(() => {
        !mount ? doPost('olap/fetchReport', {olapreport: olapid}, result => {
            if (result) {
                localStorage.pivotviewReports = JSON.stringify(result);
            }
            setMount(true);
        }) : calculateSetting();
    }, [props]);

    const calculateSetting = () => {
      setDataSourceSettings({dataSource: [...defaultDataSourceSettings.dataSource]})
    }

    function fetchReport(args) {
        let reportsCollection = [];
        let reeportsList = [];

        doPost('olap/fetchReport', {olapreport: olapid}, result => {
            if (result) {
                localStorage.pivotviewReports = JSON.stringify(result);
            }
        })

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportsCollection = JSON.parse(localStorage.pivotviewReports);
        }
        reportsCollection.map(function (item) { reeportsList.push(item.reportName); });
        args.reportName = reeportsList;
    }
    function saveReport(args) {
        let report = [];
        let isSave = false;

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            report = JSON.parse(localStorage.pivotviewReports);
        }
        if (args.report && args.reportName && args.reportName !== '') {
            report.map(function (item) {
                if (args.reportName === item.reportName) {
                    item.report = args.report;
                    isSave = true;
                }
            });
            if (!isSave) {
                report.push(args);
            }
            let toSave = JSON.stringify(report);
            localStorage.pivotviewReports = toSave;
            doPost('olap/saveReport', {olapreport: olapid, reports: toSave})
        }
    }
    function removeReport(args) {
        let reportsCollection = [];

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportsCollection = JSON.parse(localStorage.pivotviewReports);
        }
        for (let i = 0; i < reportsCollection.length; i++) {
            if (reportsCollection[i].reportName === args.reportName) {
                reportsCollection.splice(i, 1);
            }
        }
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            let toSave = JSON.stringify(reportsCollection);
            localStorage.pivotviewReports = toSave;
            doPost('olap/saveReport', {olapreport: olapid, reports: toSave})
        }
    }
    function loadReport(args) {
        let reportsCollection = [];

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportsCollection = JSON.parse(localStorage.pivotviewReports);
        }
        reportsCollection.map(function (item) {
            if (args.reportName === item.reportName) {
                args.report = item.report;
            }
        });
        if (args.report) {
            setDataSourceSettings(JSON.parse(args.report).dataSourceSettings)
            pivotObj.dataSourceSettings = JSON.parse(args.report).dataSourceSettings;
        }
    }
    function renameReport(args) {
        let reportsCollection = [];

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportsCollection = JSON.parse(localStorage.pivotviewReports);
        }
        if (args.isReportExists) {
            for (let i = 0; i < reportsCollection.length; i++) {
                if (reportsCollection[i].reportName === args.rename) {
                    reportsCollection.splice(i, 1);
                }
            }
        }
        reportsCollection.map(function (item) { if (args.reportName === item.reportName) {
            item.reportName = args.rename;
        }});
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            let toSave = JSON.stringify(reportsCollection);
            localStorage.pivotviewReports = toSave;
            doPost('olap/saveReport', {olapreport: olapid, reports: toSave})
        }
    }
    function beforeToolbarRender(args) {
        args.customToolbar.splice(6, 0, {
            type: 'Separator'
        });
        args.customToolbar.splice(9, 0, {
            type: 'Separator'
        });
    }

    function newReport() {
        pivotObj.setProperties({ dataSourceSettings: { columns: [], rows: [], values: [], filters: [] } }, false);
    }
    function chartOnLoad(args) {
        let selectedTheme = window.location.hash.split("/")[1];
        selectedTheme = selectedTheme ? selectedTheme : "Material";
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).
        replace(/-dark/i, "Dark");
    }

    return (<div className='control-pane'>
        <div className='control-section' id='pivot-table-section' style={{ overflow: 'initial' }}>
            {mount && <PivotViewComponent id={olapid} ref={(scope) => { pivotObj = scope; }} dataSourceSettings={dataSourceSettings}
                width={width || '100%'} height={height || '600'} showFieldList={showFieldList || true} showGroupingBar={showGroupingBar || true}
                gridSettings={{ columnWidth: 20 }} allowExcelExport={allowExcelExport || true} allowConditionalFormatting={allowConditionalFormatting || true}
                allowPdfExport={allowPdfExport || true} showToolbar={showToolbar || true} enableFieldSearching={enableFieldSearching || true}
                allowCalculatedField={allowCalculatedField || true} displayOption={{ view: 'Both' }} toolbar={toolbarOptions} newReport={newReport.bind(this)}
                renameReport={renameReport.bind(this)} removeReport={removeReport.bind(this)} loadReport={loadReport.bind(this)}
                fetchReport={fetchReport.bind(this)} saveReport={saveReport.bind(this)} toolbarRender={beforeToolbarRender.bind(this)}
                chartSettings={{ title: 'Analisis', load: chartOnLoad.bind(this) }}>
                <Inject services={[FieldList, GroupingBar, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting]}/>
            </PivotViewComponent>}
        </div>
    </div>);
}

OlapSample.propTypes = {
    defaultDataSourceSettings: PropTypes.object.isRequired,
    olapid: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    showFieldList: PropTypes.bool,
    showGroupingBar: PropTypes.bool,
    allowExcelExport: PropTypes.bool,
    allowConditionalFormatting: PropTypes.bool,
    allowPdfExport: PropTypes.bool,
    showToolbar: PropTypes.bool,
    enableFieldSearching: PropTypes.bool,
    allowCalculatedField: PropTypes.bool
};
export default OlapSample;
