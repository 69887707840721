import './App.css';
import Navbar from "./View/Navbar";
import {Routes, BrowserRouter as Router, Route, useLocation,} from "react-router-dom";
import routes from "./Utils/routes"
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID, experimental_extendTheme as extendMuiTheme,
} from '@mui/material/styles';
import {actionTypes} from "./Utils/Redux/reducer";
import {Alert, Snackbar} from "@mui/material";
import * as React from "react";
import store from "./Utils/Redux/store";
import Dialog from "@mui/material/Dialog";
import SignIn from "./Solutions/ERP/src/Login/SignIn";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Copyright from "./View/Copyright";
import {appStyles} from "./styles";
import {useEffect} from "react";
import {disableNetwork, enableNetwork, networkHandle} from "./Utils/Restclient/NetworkActions";
import EJ2_LOCALE from "../node_modules/@syncfusion/ej2-locale/src/es.json";
import {L10n, registerLicense, setCulture} from "@syncfusion/ej2-base";
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {mergedTheme} from "./configuration";
import theme from "./theme";

L10n.load({ es: EJ2_LOCALE.es });

const materialTheme = extendMuiTheme({...theme})

export default function App() {
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState(null);
    const classes = appStyles();
    setCulture("es");
    registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1NpRGFGfV5yd0VPalxZTnVWUj0eQnxTdEZiWX9bcnVVTmNVU0N/WA==');


    store.subscribe(() => {
        const change = store.getState();
        if(change?.notification && change?.notificationmessage){
            setValues({notification: change.notification, notificationmessage: change.notificationmessage})
        } else if(change?.unauthorizedUser != null) {
            setOpen(!change.unauthorizedUser)
        }
    })


    useEffect(() => {
        networkHandle()
    }, [])

    const handleClose = () => {
        if(values){
            store.dispatch({type: actionTypes.HIDE_NOTIFICATION})
            setValues(null);
        }
    }

    return (
          <Router>
              <MaterialCssVarsProvider
                  defaultMode={"light"}
                  theme={{ [MATERIAL_THEME_ID]: materialTheme }} >
                  <JoyCssVarsProvider defaultMode={"light"}>
                      <CssBaseline enableColorScheme theme={mergedTheme}/>
                          <Box>
                              <Navbar/>
                                  <div>
                                      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={values} autoHideDuration={3000} onClose={handleClose}>
                                          <Alert onClose={handleClose} severity={values?.notification} sx={{ mt:-2, width: '100%' }}>
                                              {values?.notificationmessage}
                                          </Alert>
                                      </Snackbar>
                                      <Dialog open={open} onClose={() => setOpen(false)}>
                                          <Grid container >
                                              <Grid item md={12}>
                                                  <SignIn />
                                              </Grid>
                                          </Grid>
                                      </Dialog>
                                            <div>
                                                <Box  style={{height:'89vh', marginTop:'70px'}} overflow={'auto'}>
                                                            <Routes>
                                                                {routes.map((r,i) =>
                                                                    <Route key={i} path={r.path} element={r.element}/>
                                                                )}
                                                            </Routes>
                                                </Box>
                                                <Copyright/>
                                            </div>
                                  </div>
                          </Box>
                  </JoyCssVarsProvider>
              </MaterialCssVarsProvider>
          </Router>

  );
}
