import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import WorkspaceMapView from "./MapView/WorkspaceMapView";

export const WorkspaceMapDialog = (props) => {

    useEffect(() => {

    }, [props]);

    return (
        <Box>
            <WorkspaceMapView workspaceid={props.workspaceid}/>
        </Box>
    );
};

export default WorkspaceMapView;
