import * as React from 'react';
import {useCallback, useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import Dialog from "@mui/material/Dialog";
import EditProviderproductDialog from "./EditProviderproductDialog";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {updateProviderproduct} from "./actions";
import {defaultSendMail} from "../../../../../../View/Form/utils";
import CustomDialog from "../../../../../../View/CustomDialog";

const providerproducttableUI = {
    fields: {
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 1.2},
        since : {name: 'since', label: 'Desde',typeName:'Date', flex: 0.45},
        until : {name: 'until', label: 'Hasta',typeName:'Date', flex: 0.45},
        fromquantity : {name: 'fromquantity', label:'Desde cantidad' , typeName: 'Number', flex: 0.35},
        toquantity : {name: 'toquantity', label:'Hasta cantidad' , typeName: 'Number', flex: 0.35},
        price : {name: 'price', label:'Precio' , typeName: 'Number', flex: 0.4},
        reference : {name: 'reference', label:'Referencia' , typeName: 'String', flex: 0.4, align: 'center'},
    },
    keyComponent: 'ProviderproductView',
}

export default function ProviderproductView(props) {
    const {providerid} = props;
    const [records, setRecords] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const [editDialog, setEditDialog] = React.useState(false);

    useEffect(() =>{
        refresh()
    }, [providerid, editDialog, props])

    const refresh = useCallback(() =>{
        const filters = {
            entity : {name: 'providerproduct'},
            fields: {providerid : {name: 'providerid', operator: 'OPERATOR_EQUAL', value: providerid}},
        }
        searchByCriteria(filters, result =>{
            if(result){
                setRecords(result);
            }
        });
        }, [editDialog, props])

    const onChange = (e) => {
        const record = records.filter(r => r['providerproductid'] === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        updateProviderproduct(toSend)
    }

    const onEdit = (object) => {
        setSelected(object)
        setEditDialog(true);
    }

    const onDelete = (providerproduct) => {
        doDelete('rest/providerproduct/delete/' + providerproduct.providerproductid, null, refresh)
    }

    const closeEditDialog = () =>{
        setEditDialog(!editDialog);
    }


    function addObject() {
        setSelected({providerid:providerid});
        setEditDialog(true);
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <CustomDialog title={selected ? 'Editar tarifa' : 'Nueva tarifa'}
                maxWidth={"md"} open={editDialog} onClose={closeEditDialog}>
                <EditProviderproductDialog setOpen={closeEditDialog} selected={selected}/>
            </CustomDialog>
            <Button size={'small'} variant="contained" onClick={addObject} startIcon={<AddIcon />} sx={{m: 1, mb: 2}}> Añadir tarifa </Button>
            <Box overflow={'auto'}>
                <GridTable ui={providerproducttableUI}
                           sx={{height: '70vh'}} actionFlex={0.3}
                           density={'compact'}
                           records={records} rowId={'providerproductid'}
                           onChange={onChange} onEdit={onEdit} onDelete={onDelete}
                           toolBar={'S'}/>
            </Box>
        </Box>
    )
}
