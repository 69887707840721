import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import HorizontalTabList from "../../Tab/HorizontalTabList";

export default function ObjectManagementDialog(props){
    const {objectid, entityDefinition} = props;
    const [tabs, setTabs] = React.useState(null);
    const [currentObject, setCurrentObject] = React.useState(null);

    useEffect(() => {
        if (currentObject != objectid) {
            setTabs(entityDefinition.uiTab(objectid).uiTabs)
            setCurrentObject(objectid)
        }

    }, [objectid])

    return(
        <Box sx={{m: 1, width: '100%', height: '100%', typography: 'body1'}}>
            {objectid && tabs && <HorizontalTabList visible={true} showButton={false} expansible={false} selectTab={1} sideBarTab={tabs}/>
            }
        </Box>
    )
}
