import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import NewProductCompositiondetailDialog from "./NewProductCompositiondetailDialog";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields: {
        compositiondetailid : {name: 'compositiondetailid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.5, sort: 'asc', optional: true },
        rawmaterial : {name: 'rawmaterial', label:'Material consumido' , typeName: 'es.rbm.model.jpa.Product', editable: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}

export default function ProductcompositionView(props) {
    const {productid} = props;
    const [records, setRecords] = React.useState([]);
    const [compositiondetail, setCompositiondetail] = React.useState(null);
    const [openNewCompositiondetail, setOpenNewCompositiondetail] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newCompositiondetail = () => {
        setOpenNewCompositiondetail(true);
    }

    const onEdit = (compositiondetailid) => {
        setCompositiondetail(compositiondetailid);
        setOpenNewCompositiondetail(!openNewCompositiondetail)
    }

    const onDelete = (compositiondetailid) =>{
        deleteObject(compositiondetailid,{name:"compositiondetail", keyField:'compositiondetailid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.compositiondetailid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('compositiondetail', toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })
    }

    useEffect(() =>{
        if(!openNewCompositiondetail){
            const filters = {
                fields: {productid : {name: 'productid', operator: 'OPERATOR_EQUAL', value: productid},},
                entity : { name: 'compositiondetail'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setCompositiondetail(null);
            });
        }
    }, [refreshObjects, openNewCompositiondetail, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <CustomDialog title={compositiondetail ? 'Editar composición' : 'Nueva composición'}
                    open={openNewCompositiondetail} onClose={() => setOpenNewCompositiondetail(false)}>
                    <NewProductCompositiondetailDialog compositiondetailid={compositiondetail} product={productid} setOpen={setOpenNewCompositiondetail} open={openNewCompositiondetail}/>
                </CustomDialog>
                <Button size={'small'} variant="contained" onClick={newCompositiondetail} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir detalle
                </Button>
                <GridTable ui={ui} sx={{mt: 2}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'compositiondetailid'}
                           onChange={onChange} toolBar
                />
            </Box>
        </Box>
    );
}
