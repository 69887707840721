import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../View/Button/SuccessButton";
import {doPostRest, doPostRestList} from "../../../../../Utils/Restclient/NetworkRestActions";

const defaultUI = {
    fields:{
        workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', size: 12, editable: false},
        since : {name: 'since', label:'Desde' , typeName: 'Datetime', size: 6},
        until : {name: 'until', label:'Hasta' , typeName: 'Datetime', size: 6},
        workspaceid : {name: 'workspaceid', label:'Espacio' , typeName: 'es.rbm.model.jpa.Workspace', size: 6, optional: true},
        taskid : {name: 'taskid', label:'Tarea' , typeName: 'es.rbm.model.jpa.Task', size: 6, optional: true},
    }
}

export default function EditImputationDialog(props) {
    const [imputationid, setImputationid] = React.useState(props.parentState?.selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [ui, setUI] = React.useState(defaultUI);
    const [editMode, setEditMode] = React.useState(false);
    const {changeOpenEditImputation} = props;

    const handledChange = (record, complete) => {
        setImputationid({...record});
        setComplete(complete);
    }

    useEffect(() => {
        if (imputationid && imputationid.imputationid) {
            setEditMode(true);
            setImputationid({...imputationid});
            const newUI = {
                fields: {...defaultUI.fields}
            }
            newUI.fields.workerid.isMulti = false;
            setUI(newUI);
        }
    }, [props])

    const onSuccessClick = () => {
        if (editMode) {
            const toSend = {...imputationid,}
            doPostRest('imputation', toSend, changeOpenEditImputation)
        } else {
            const {imputation, workerid, since, until, workspaceid, taskid} = imputationid;
            const toSend = workerid.map(w => {
                return {
                    imputation, since, until, workspaceid, taskid, workerid: w,
                }
            });
            doPostRestList('imputation', toSend, changeOpenEditImputation)
        }
    };

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Grid container>
                <Grid item md={12}>
                    <SingleRecordForm ui={ui} records={imputationid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
            </Grid>
        </Box>
    )
}
