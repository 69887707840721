import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import Container from "@mui/material/Container";
import NewObjectDialog from "./NewObjectDialog";
import ObjectManagementDialog from "./ObjectManagementDialog";
import {deleteObject} from "./actions";
import GridTable from "../GridTable";
import {error, success} from "../../../Utils/Notification/notifications";
import {doGet, doGetDownloadFile, doPost, doPostFile, doPut} from "../../../Utils/Restclient/NetworkActions";
import {IconButton, LinearProgress, ListItem, Slide} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Grid from "@mui/material/Grid";
import SingleRecordForm from "../SingleRecordForm";
import SuccessButton from "../../Button/SuccessButton";
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import DragDropFiles from "../../DragDropFiles/DragDropFiles";
import { Stack } from '@mui/material';
import Typography from "@mui/material/Typography";
import PDFViewer from "../../Document/PDFViewer";
import RefreshIcon from '@mui/icons-material/Refresh';
import SendMailView from "../MailForm/SendMailView";
import StickyList from "../../StickyList";
import {appStyles} from "../../../styles";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {getToken, maxHeightViews} from "../../../Utils/Constant";
import {OPERATORS} from "../../../Utils/Persistence/PersistenceConstant";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {useTheme} from "@mui/material/styles";
import CustomDialog from "../../CustomDialog";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ObjectManagementView(props) {
    const classes = appStyles();
    const {entityDefinition, selected} = props;
    const [records, setRecords] = React.useState([]);
    const [refreshObjects, setRefreshObjects] = React.useState(true);
    const [objectid, setObjectid] = React.useState({});
    const [openEditObject, setOpenEditObject] = React.useState(false);
    const [openNewObject, setOpenNewObject] = React.useState(false);
    const [openFilters, setOpenFilters] = React.useState(entityDefinition?.filters?.startBegin);
    const [filters, setFilters] = React.useState(entityDefinition.filters?.initialsValues || {});
    const [completeFilters, setCompleteFilters] = React.useState(entityDefinition?.filters?.fields ?
        Object.keys(entityDefinition.filters.fields).filter(key => !(entityDefinition.filters.fields[key].optional)).length === 0 : true);
    const [loading, setLoading] = React.useState(true);
    const [openImportData, setOpenImportData] = React.useState(false);
    const [fileToImport, setFileToImport] = React.useState(null);
    const [pdf, setPDF] = React.useState(false);
    const [openMail, setOpenMail] = React.useState(false);
    const [mailRecords, setMailRecords] = React.useState(null);
    const theme = useTheme();

    const addObject = () => {
        setOpenNewObject(true);
    }
    const onEdit = (objectid) =>{
        setObjectid(objectid);
        setOpenEditObject(true);
    }

    const PDFSet = (pdf) => {
        setPDF(pdf)
    }

    const onDelete = (objectid) =>{
        deleteObject(objectid,entityDefinition.entity ,() => setRefreshObjects(!refreshObjects))
    }

    const handledChange = (newFilter, complete) =>{
        const filterToSave = Object.keys(newFilter).reduce((l, c) => {
            return newFilter[c] ? {...l, [c] : newFilter[c]} : {...l};
        }, {});
        setFilters({...filterToSave});
        setCompleteFilters(complete)
    }

    const handledFileAdd = (file1) =>{
        setFileToImport(file1.target.value);
    }

    const doImportData = () =>{
        if (fileToImport && entityDefinition.entity.urlExcelImport) {
            doPostFile(entityDefinition.entity.urlExcelImport, fileToImport,() => refresh(() => {
                success("Se ha realizado la importacion correctamente", () => {
                    setRefreshObjects(!refreshObjects);
                    setFileToImport(null);
                })
            }),false)
        } else if(entityDefinition.entity.urlImport){
            doGet(entityDefinition.entity.urlImport,() => refresh(() => {
                success("Se ha realizado la importacion correctamente", () => {
                    setRefreshObjects(!refreshObjects);
                    setFileToImport(null);
                })
            }),false)
        } else {
           error("Ha habido un problema")
        }
        setFileToImport(null);
        setOpenImportData(false);
    }

    const downloadTemplate = () =>{
        doGetDownloadFile(entityDefinition.entity.urlDownloadTemplate, 'Plantilla_' + entityDefinition.entity.label + '.xlsx');
    }

    const searchByCriteria = (callback) => {
        const keys = Object.keys(filters);
        if (keys.length>0) {
            const itemsQuery = keys.map(f => {
                const field = entityDefinition.filters.fields[f];
                const timeFields = ['Date', 'Datetime']
                const operator = field.operator;
                const hours = new Date(filters[f]);
                hours.setHours(0,0,0,0);
                let newVar = {
                    field: field.name.split("_")[0],
                    operator: operator,
                    value: timeFields.includes(field.typeName) ? (operator === OPERATORS.OPERATOR_BETWEEN ? hours.getTime() : new Date(filters[f]).getTime()) : filters[f],
                };

                if (operator === OPERATORS.OPERATOR_BETWEEN) {
                    hours.setHours(23,59,59,59)
                    newVar = {...newVar, value2: timeFields.includes(field.typeName) ? hours.getTime() : filters[f] }
                }
                return newVar
            }).filter(data => data.value !== null);
            const request = {
                clazz: entityDefinition.entity.name,
                items: [...itemsQuery]
            }
            if (request?.items?.length > 0) {
                doPost("rest/getByQuery", request, result =>{
                    if (result) {
                        setRecords(result);
                        setLoading(false);
                    }
                }, true)
            } else {
                getAlls(callback)
            }
        } else {
            getAlls(callback)
        }
    }

    const getAlls = (callback) => {
        const entity = entityDefinition.entity;
        const url = entity.urlRefresh ?  entity.urlRefresh : 'rest/'+ entity.name +'/getAll';
        doGet(url, response =>{
            setLoading(false);
            setRecords(response);
            callback && callback()
        })
    }

    const onChange = (newRecord, oldRecord, refresh) => {
            doPut('rest/'+ entityDefinition.entity.name +'/update',newRecord, response =>{
                if (!response) {
                    const index = records.indexOf(oldRecord);
                    records[index] = response;
                    setRecords([...records]);
                    error('Fallo al modificar ' + entityDefinition.entity.label)
                } else if (refresh) {
                    let index = records.indexOf(oldRecord);
                    if (index === -1) {
                        const record = records.filter(r => r[entityDefinition.entity.keyField] === oldRecord[entityDefinition.entity.keyField]);
                        if (record.length){
                            index = records.indexOf(record[0]);
                        }
                    }
                    records[index] = newRecord;
                    setRecords([...records]);
                }
            })
    }

    const refresh = (callback) => {
        searchByCriteria(callback)
    }

    useEffect(() =>{
        if(!openNewObject && !openEditObject && !openFilters){
            if(entityDefinition.urlRecords && selected){
                doGet(entityDefinition.urlRecords(selected), response =>{
                    setLoading(false);
                    setRecords(response);
                })
            } else {
                refresh()
            }
        }
    }, [openNewObject, openEditObject, refreshObjects, objectid]) // eslint-disable-line react-hooks/exhaustive-deps

    const getText = () => {
      return filters && Object.keys(filters).length > 0 ? 'BUSCAR FILTROS' : 'BUSCAR TODO';
    }

    let onClickFilter = () => setOpenFilters(!openFilters);

    const handleNewObject = (newObject) => {
        if (newObject && entityDefinition?.filters?.openEdit) {
            onEdit(newObject)
        }
    }

    const showList = entityDefinition.entity.info && records?.length;
    const classname = entityDefinition?.entity?.label?.toLowerCase();
    return (
            <Box sx={{height: '83vh'}}>
                <CustomDialog fullWidth maxWidth="md" open={pdf} onClose={() => setPDF(null)} >
                    <PDFViewer pdfUrl={pdf} onClose={() => setPDF(null)}/>
                </CustomDialog>
                <Dialog fullWidth maxWidth={"xl"} open={openMail} onClose={() => setOpenMail(false)}>
                    <SendMailView closeDialog={setOpenMail} records={mailRecords}/>
                </Dialog>
                <CustomDialog title={'Nuevo ' + classname}
                    fullWidth={entityDefinition.onClickNewObject} maxWidth={entityDefinition.onClickNewObject? "xl" : null} open={openNewObject} onClose={() => setOpenNewObject(false)} disableEscapeKeyDown={false}>
                    <Box>
                        {entityDefinition.onClickNewObject ?
                            <Box>
                                {entityDefinition.onClickNewObject.component(setOpenNewObject, openNewObject)}
                            </Box>
                            :
                            <NewObjectDialog setOpen={setOpenNewObject} handleNewObject={handleNewObject} open={openNewObject} entityDefinition={entityDefinition}/>
                        }
                    </Box>
                </CustomDialog>
                <Dialog TransitionComponent={Transition} fullScreen open={openEditObject} onClose={() => setOpenEditObject(false)} disableEscapeKeyDown>
                    <AppBar sx={{ position: 'relative' }} style={{
                        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        color: '#ffffff',
                        boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1)',

                    }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {/* TODO Show title AppBar */}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => setOpenEditObject(!openEditObject)}
                                aria-label="close"
                                sx={{ marginLeft: 'auto' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <Grid container>
                            {showList ?
                                <Grid item md={2} sx={{mt:5}}>
                                    <Grid container>
                                        <Grid item xs={12} ml={2}>
                                            <StickyList objectid={objectid} items={records} setSelected={onEdit} infoEntity={entityDefinition.entity}/>
                                        </Grid>
                                    </Grid>
                                </Grid> : ''
                            }
                            <Grid item md={ showList ? 9.75 : 12}>
                                <Box sx={{height: '60vh'}}>
                                    <ObjectManagementDialog objectid={objectid} entityDefinition={entityDefinition} />
                                </Box>
                            </Grid>
                        </Grid>
                </Dialog>
                { entityDefinition.importComponent ? entityDefinition.importComponent.component(setOpenImportData, openImportData)
                    :
                <CustomDialog fullWidth title={'Seleccione el archivo a importar'} open={openImportData} disableEscapeKeyDown={false} onClose={() => setOpenImportData(false)}>
                    <Box>
                        <Grid container>
                            <Grid item md={12} sx={{margin: 2}}>
                                <Box sx={{mb: 5}}>
                                    <DragDropFiles onLoadFile={handledFileAdd} name={'file'} file={fileToImport}/>
                                </Box>
                                { entityDefinition.entity.urlDownloadTemplate && <SuccessButton text={'DESCARGAR PLANTILLA'} variant="outlined" fullWidth sx={{mb: 1}} onClick={downloadTemplate} color={'warning'}/>}
                                <SuccessButton text={'IMPORTAR'} fullWidth sx={{mb: 1}} onClick={doImportData}/>
                            </Grid>
                        </Grid>
                    </Box>
                </CustomDialog>}
                <CustomDialog fullWidth title={'Filtrar ' + entityDefinition.entity.label.toLowerCase()}
                              sx={{padding: 20}} open={openFilters} disableEscapeKeyDown={false}
                              onClose={() => { setLoading(false); setOpenFilters(!openFilters)}}>
                    <Container>
                        <Box component="form" noValidate>
                            <Grid container>
                                <Grid item md={12} sx={{mt: 2, mb: 3}}>
                                    <Grid container spacing={1}>
                                        <Grid item md={12} overflow={'auto'} sx={{maxHeight: '35vh', pt: 2, pr: 2}}>
                                            <SingleRecordForm sx={{}} ui={entityDefinition.filters} records={filters} onChange={handledChange}/>
                                        </Grid>
                                        <Grid item md={4}>
                                            {!entityDefinition.entity.hideNewButton &&
                                                <Button className={classes.DefaultButton} size={"small"} fullWidth variant="contained" onClick={addObject} startIcon={<AddIcon />} sx={{mt: 1}}>
                                                    Añadir
                                                </Button>}
                                        </Grid>
                                        <Grid item md={5}>
                                            <SuccessButton text={getText()} disabled={!completeFilters} fullWidth sx={{mt: 1}} onClick={(e) => {e.preventDefault()
                                                setOpenFilters(!openFilters)
                                                searchByCriteria()
                                            }}/>
                                        </Grid>
                                        <Grid item md={0.5}/>
                                        <Grid item md={2}>
                                            <SuccessButton type={'reset'} variant={'text'} startIcon={<CleaningServicesIcon/>} text={'LIMPIAR'} fullWidth sx={{ml: 1, mt: 1}} onClick={(e) => {e.preventDefault();
                                                setFilters({});
                                            }}/>
                                        </Grid>
                                        <Grid item md={0.5}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </CustomDialog>
                    <Grid container>
                        <Grid item md={11} >
                            <Stack spacing={2}>
                                <ListItem>
                                    {!entityDefinition.entity.hideNewButton &&
                                    <Button className={classes.DefaultButton} variant="contained" size={'small'} onClick={addObject} startIcon={<AddIcon />} sx={{m: 0.5}}>
                                        Añadir {entityDefinition.entity.label.toLowerCase()}
                                    </Button>}
                                    {(entityDefinition.entity.urlExcelImport || entityDefinition.entity.urlImport || entityDefinition.importComponent) &&
                                        <Button className={classes.DefaultButton} size={'small'} variant="contained" onClick={() => setOpenImportData(true)} startIcon={<CallReceivedIcon />} sx={{m: 0.5}}>
                                            Importar
                                        </Button>
                                    }
                                </ListItem>
                            </Stack>
                        </Grid>
                        {entityDefinition.filters ?
                            <Grid item md={1} sx={{mt:2}}>
                                <IconButton onClick={onClickFilter} color={'warning'} variant="contained" size="small">
                                    {filters && Object.keys(filters).length > 0 ? <FilterAltIcon/> : <FilterAltOffIcon/>}
                                </IconButton>
                                <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="small">
                                    <RefreshIcon/>
                                </IconButton>
                            </Grid> :
                            <Grid item md={1} sx={{mt:2}}>
                                <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="small">
                                    <RefreshIcon/>
                                </IconButton>
                            </Grid>}
                    </Grid>
                    <GridTable ui={entityDefinition.uiTable} onDelete={onDelete} onEdit={onEdit}
                               records={records} rowId={entityDefinition.entity.keyField}
                               loading={loading}
                               sx={{height: maxHeightViews}}
                               pdfSet={PDFSet}
                               downloadPDF={entityDefinition.uiTable.actions?.downloadPDF?.fun}
                               onPrint={entityDefinition.uiTable.actions?.onPrint}
                               sendEmail={entityDefinition.uiTable.actions?.sendEmail?.fun &&
                                   ((entity) => entityDefinition.uiTable.actions?.sendEmail?.fun(entity,setOpenMail, setMailRecords))}
                               onChange={onChange} toolBar colorRow={entityDefinition.entity.colorRow}
                               colorValue={entityDefinition.entity.colorValue} actionFlex={entityDefinition.uiTable.actionFlex}
                    />
            </Box>
    );
}
