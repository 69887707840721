import React from "react";
import { Popup } from "react-leaflet";

const MarkerPopup = (props) => {
    const { workerid, timetick, timeticktype, workspaceid, taskid, terminalid, dataperioddetailid } = props.data;
    const ticksTypes = { 1 : 'Entrada', 2: 'Salida'}
    return (
        <Popup>
            <div><strong>{workerid.name + ' ' + workerid.surname}</strong></div>
            {timeticktype && <div>{ ticksTypes[timeticktype]}</div>}
            <div>{'Hora: ' + new Date(timetick).toLocaleTimeString('es-ES', {hour: 'numeric', minute: 'numeric', second: 'numeric'})}</div>
            {workspaceid && <div>{'Espacio: ' + workspaceid.workspace}</div>}
            {taskid && <div>{'Tarea: ' + taskid.task}</div>}
            {terminalid && <div>{'Terminal ' + terminalid.terminal}</div>}
            {dataperioddetailid && <div>{'Parte: ' + dataperioddetailid.dataperiodid.dataperiodid}</div>}
        </Popup>
      );
};

export default MarkerPopup;
