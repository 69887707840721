import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateDataperioddetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {addNewObject} from "../../../../../../View/Form/CrudForm/actions";
import {success} from "../../../../../../Utils/Notification/notifications";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";

const defaultUI = {
    fields:{
        workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', urlRecords: 'worker/getActiveWorker'},
        taskid : {name: 'taskid', label: 'Tarea', typeName:'es.rbm.model.jpa.Task'},
        hours : {name: 'hours', label: 'Horas',typeName:'Number'},
    }
}

export default function AluEditDataperioddetailDialog(props) {
    const {open, setOpen, selected, loadUI} = props;
    const [dataperioddetailid, setDataperioddetail] = React.useState(selected);
    const [isComplete, setComplete] = React.useState(false);
    const [ui, setUI] = React.useState(loadUI ? loadUI : defaultUI);

    const handledChange = (record, complete) => {
        setDataperioddetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if(dataperioddetailid && dataperioddetailid.dataperioddetailid){
        } else {
            const {taskid, workspaceid, productid} = selected.dataperiodid;
            const newFields =  {...defaultUI.fields, workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', isMulti: true, urlRecords: 'worker/getActiveWorker'}}
            setUI({...defaultUI, fields: newFields})
            setDataperioddetail({...dataperioddetailid, taskid, workspaceid, productid})
        }
    }, [props])

    const onSuccessClick = (e) => {e.preventDefault();updateDataperioddetail(dataperioddetailid, () => setOpen(!open))};

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={dataperioddetailid} onChange={handledChange} />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
