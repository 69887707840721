import * as React from 'react';
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent } from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

export default function CustomDialog(props) {
    const { open, onClose, title, children, actions, titlevariant } = props;
    const theme = useTheme();

    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const [initialPos, setInitialPos] = React.useState({ x: 0, y: 0 });
    const [dragging, setDragging] = React.useState(false);

    React.useEffect(() => {
        const handleDrag = (e) => {
            if (dragging) {
                const newX = e.clientX - initialPos.x;
                const newY = e.clientY - initialPos.y;
                setPosition({ x: newX, y: newY });
            }
        };

        const handleDragEnd = () => {
            setDragging(false);
        };

        if (dragging) {
            window.addEventListener('mousemove', handleDrag);
            window.addEventListener('mouseup', handleDragEnd);
        } else {
            window.removeEventListener('mousemove', handleDrag);
            window.removeEventListener('mouseup', handleDragEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleDrag);
            window.removeEventListener('mouseup', handleDragEnd);
        };
    }, [dragging, initialPos]);

    const handleDragStart = (e) => {
        setDragging(true);
        setInitialPos({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    return (
        <Dialog
            open={open}
            fullWidth
            {...props}
            PaperProps={{
                style: {
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    transition: dragging ? 'none' : 'transform 0.1s ease-out',
                },
            }}
        >
            <AppBar
                sx={{ position: 'relative', cursor: 'move' }}
                style={{
                    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    color: '#ffffff',
                    boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1)',
                }}
                onMouseDown={handleDragStart}
            >
                <Grid container>
                    <Grid item md={11} alignContent={'center'} justifyItems={'center'} align={'center'}>
                        <Typography sx={{ flex: 1 }} variant={titlevariant || 'h6'} component="div">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item md={1} align={'right'}>
                        <IconButton
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </AppBar>
            <DialogContent>
                {children}
            </DialogContent>
            {actions && (
                <DialogActions>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
}
