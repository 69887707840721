import React from "react";
import { Marker } from "react-leaflet";
import {iconTicks} from "./VenueLocationIcon";
import MarkerPopup from "./MarkerPopup";

const TimetickMarkers = (props) => {
  const { timeticks } = props;
  const markers = timeticks.map((tt, i) => (
    <Marker key={i} position={{lat: tt.latitude, lng: tt.longitude}} icon={iconTicks[tt.timeticktype || 0]}>
      <MarkerPopup data={tt} />
    </Marker>
  ));
  return <>{markers}</>;
};

export default TimetickMarkers;
