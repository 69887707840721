import {doGet, doPost} from "../../../../../../../Utils/Restclient/NetworkActions";

export const getConstructionInformation = (constructionid, callback) => {
      if (constructionid) {
        const url = 'construction/getConstructionInformation/' + constructionid?.constructionid;
        doGet(url, r => {
            if(r){
                callback && callback(r);
            }
        })
    } else {
        callback && callback()
    }
}

export const saveConstructionCoordinates = (json, construction, callback) => {
    const url = 'construction/saveConstructionPolygon';
    const request = {json: json, constructionid: construction};
    doPost(url, request, () => {
        callback && callback()
    });
}
