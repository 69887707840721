import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";


const ui = {
    fields:{
        compositiondetailid : {name: 'compositiondetailid', label:'Codigo' , typeName: 'String', editable: false, optional: true },
        rawmaterial : {name: 'rawmaterial', label:'Material consumido' , typeName: 'es.rbm.model.jpa.Product', editable: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}
export default function NewProductCompositiondetailDialog(props) {
    const {open, setOpen, product} = props;
    const [compositiondetailid, setCompositiondetailid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setCompositiondetailid({...record});
        setComplete(complete);
    }

    const addCompositiondetail = (compositiondetailid, callback) => {
        const toSend = {...compositiondetailid, productid: {productid: product.productid}}
        doPost('rest/compositiondetail/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const compositiondetailid1 = props.compositiondetailid;
        setCompositiondetailid(compositiondetailid1 ? compositiondetailid1 : {});
    }, [props.compositiondetailid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addCompositiondetail(compositiondetailid, () => setOpen(!open))
    };


    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={compositiondetailid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    );
}
