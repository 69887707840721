import * as React from 'react';
import {List, ListItem, Sheet} from "@mui/joy";
import ListItemButton from "@mui/material/ListItemButton";
import {useEffect} from "react";
import {converterRecord} from "./Form/SingleSelectForm";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import uiDefinition from "../Utils/uiDefinition";

export default function StickyList(props) {
    const {items, setSelected, infoEntity, objectid} = props;
    const [itemsToShow, setItemsToShow] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [title, setTitle] = React.useState('');


    const handleOnClick = (value1) =>{
        const selected = itemsToShow[value1.target.id]
        setSelected(selected);
        setTitle(selected);
        setFilter('')
    }

    useEffect(() =>{
        const uiDefinitionElement = uiDefinition[infoEntity?.info?.typeName];
        const sorted = uiDefinitionElement ? items.sort((a, b) => a[uiDefinitionElement.keyField] - b[uiDefinitionElement.keyField]) : items;
        setItemsToShow(sorted)
        setTitle(objectid);
    }, [props])

    const handleSearchChange = (var1) => {
        const value = var1.target.value;
        setFilter(value);
        if(value){
            const itemFiltered = items.filter(it => converterRecord(it, infoEntity.info).label.toLowerCase().includes(value.toLowerCase()));
            setItemsToShow(itemFiltered);
        } else{
            setItemsToShow(items)
        }
    }

    return (
        <Paper sx={{backgroundColor: 'white', p: 1}}>
            {title &&
                <Typography variant="body2" sx={{ mb:2, fontWeight: 'bold'}} align={'center'}>
                    {converterRecord(title, infoEntity.info).label}
                </Typography>}

            <Box sx={{ mb:1, alignItems: 'center' }}>
                <TextField
                    value={filter}
                    onChange={handleSearchChange}
                    fullWidth
                    name={'search' + infoEntity.info}
                    label={'Buscar ' + infoEntity.label}
                    placeholder={'Buscar ' + infoEntity.label + '...'}
                    id={'search' + infoEntity.info}
                />
            </Box>
                <Sheet variant="outlined" sx={{maxHeight: '70vh', overflow: 'auto'}}>
                        <List size={'xs'} sx={{maxHeight: '100%', overflow: 'vertical'}}>
                            {itemsToShow && itemsToShow.map((item, index) => (
                                <ListItem key={index}>
                                        <ListItemButton key={index} id={index} onClick={handleOnClick}>{converterRecord(item, infoEntity.info).label}</ListItemButton>
                                    </ListItem>))}
                        </List>
                </Sheet>
        </Paper>
    );
}
