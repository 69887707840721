import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateProviderproduct} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {productUI} from "../Product/productCrud";

const ui = {
    fields:{
        providerproductid : {name: 'providerproductid', label:'Codigo' , typeName: 'String', editable: false, optional: true},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false, addObject: productUI },
        since : {name: 'since', label: 'Desde',typeName:'Date'},
        until : {name: 'until', label: 'Hasta',typeName:'Date'},
        fromquantity : {name: 'fromquantity', label:'Desde cantidad' , typeName: 'Number'},
        toquantity : {name: 'toquantity', label:'Hasta cantidad' , typeName: 'Number'},
        price : {name: 'price', label:'Precio' , typeName: 'Number'},
        reference : {name: 'reference', label:'Referencia' , typeName: 'String', flex: 0.4, align: 'center', optional: true},
    }
}

export default function EditProviderproductDialog(props) {
    const {setOpen, selected} = props;
    const [providerproduct, setProviderproduct] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) => {
        setProviderproduct({...record});
        setComplete(complete);
    }

    useEffect(() =>{
    }, [props])

    const onSuccesClick = (e) => {
        e.preventDefault()
        updateProviderproduct(providerproduct, () => setOpen())
    };

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={providerproduct} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccesClick} disabled={!isComplete}/>
        </Box>
    )
}
