import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";


const ui = {
    fields:{
        day : {name: 'day', label:'Fecha' , typeName: 'Date', editable: true, size: 12},
        workernote : {name: 'workernote', label:'Nota' , typeName: 'String', editable: true, multiline: true, size: 12, rows: 5},
    },
    keyComponent: 'NewWorkerWorkernoteDialog'
}
export default function NewWorkerWorkernoteDialog(props) {
    const {open, setOpen, worker} = props;
    const [workernoteid, setWorkernoteid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setWorkernoteid({...record});
        setComplete(complete);
    }

    const addWorkernote = (workernoteid, callback) => {
        const toSend = {...workernoteid, workerid: {workerid: worker.workerid}}
        doPost('rest/workernote/create', toSend, () => callback && callback())
    }

    useEffect(() => {
        const workernoteid1 = props.workernoteid;
        setWorkernoteid(workernoteid1 ? workernoteid1 : {});
    }, [props.workernoteid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addWorkernote(workernoteid, () => setOpen(!open))
    };

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={workernoteid} onChange={handledChange} />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    );
}
