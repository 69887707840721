import React, {useState, useEffect, useRef} from "react";
import {LayersControl, MapContainer, Polygon, Popup, TileLayer} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import TimetickMarkers from "./TimetickMarkers";
import {getRelativeMaxViews, maxHeightViews, timeticktypes} from "../../../../../../Utils/Constant";

const { BaseLayer } = LayersControl;

const uiFilters = {
  fields: {
    date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 2, height: 'small'},
    workspaceid : {name: 'workspaceid', label: 'Espacio de trabajo' ,typeName: 'es.rbm.model.jpa.Workspace', size: 2, height: 'small', optional: true},
    timeticktype : {name: 'timeticktype', label:'Tipo' , typeName: 'Enum', valueOptions: timeticktypes, size: 2},
    workerid : {name: 'workerid', label: 'Trabajadores' ,typeName: 'es.rbm.model.jpa.Worker', size: 6, height: 'small', isMulti: true},
  }
}

const TimetickMapView = (props) => {
  const [state, setState] = useState({
    zoom: 15,
    data: [],
  });
  const [filters, setFilters] = React.useState({date: new Date().getTime(), workerid: [], workspaceid: null, timeticktype: null});
  const mapRef = useRef(null);
  const {height, filterDate} = props;

  useEffect(() => {
      refresh()
  }, [props, filters]);

  const refresh = (day) => {
    let date = filters?.date? filters.date : new Date().getTime();
    let isValidDate = Date.parse(date)
    let dateTicks;
    if (isValidDate && Object.keys(date).length>0){
      dateTicks = date;
    } else if(day){
      dateTicks = day;
    } else{
      dateTicks = Date.parse(new Date());
    }

    doGet("presence/getTimetickBySubjectGroup/" + dateTicks, timeticks =>{
      const workerids = filters.workerid.map(f => f.workerid);
      let ticksList = timeticks.reduce((l, c) => {
        return filters.workerid.length ? (workerids.includes(c.worker.workerid) ? [...l, ...c.ticks] : [...l]) : [...l, ...c.ticks]
      }, []);

      if (filters.workspaceid) {
        ticksList = ticksList.filter(tt => tt.workspaceid?.workspaceid === filters.workspaceid.workspaceid);
      }

      if (filters.timeticktype) {
        ticksList = ticksList.filter(tt => tt.timeticktype && timeticktypes[tt.timeticktype - 1] === filters.timeticktype);
      }

      const tickWithLocation = ticksList.filter(tt => tt.latitude);
      const currentLocation = {
        lat: tickWithLocation.length > 0 ?  tickWithLocation[0].latitude : 38.08635047,
        lng: tickWithLocation.length > 0 ?  tickWithLocation[0].longitude : -0.65302153,
      };
      panToNewLocation(currentLocation);
      setState({
        ...state,
        data: ticksList,
        currentLocation,
      });
    })
  }

  const panToNewLocation = (currentLocation) => {
    const newLocation = [currentLocation.lat, currentLocation.lng];
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  const handledChange = (filter) =>{
    setFilters({...filter});
  }

  return (
      <Box>
        {filterDate !== false && <SingleRecordForm ui={uiFilters} records={filters} sx={{backgroundColor: 'white', pb:1, pr: 1, mt: 1}} onChange={handledChange}/>}
        {state.currentLocation &&
            <MapContainer center={state.currentLocation} zoom={state.zoom} style={{ marginTop: 7, height: height || getRelativeMaxViews(-3), width: '100%' }} ref={mapRef}>
              <LayersControl position="topright">
                <BaseLayer checked name="Relieve">
                  <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                </BaseLayer>
                <BaseLayer name="Satélite">
                  <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      attribution="Tiles &copy; Esri - Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  />
                </BaseLayer>
                <BaseLayer name="Topo">
                  <TileLayer
                      url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                      attribution='Map data: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
                  />
                </BaseLayer>
              </LayersControl>
              <TimetickMarkers timeticks={state.data}/>
            </MapContainer>}
      </Box>
  );
};

export default TimetickMapView;
