import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import {doPost} from "../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../View/Button/SuccessButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {OPERATORS} from "../../../../Utils/Persistence/PersistenceConstant";
import {searchByCriteria} from "../../../../Utils/Persistence/PersistenceQuery";
import SaveRoundedButton from "../../../../View/Button/SaveRoundedButton";

const getUnits = (record, callback) => {
    const {productid} = record;
    if(productid) {
        const filters = {
            entity : {name: 'container'},
            fields: {
                productid : {name: 'productid', operator: OPERATORS.OPERATOR_EQUAL, value: productid},
            },
        }
        searchByCriteria(filters, result => {
            const containerunits = result.map(r => r.movementunitid);
            callback && callback([...containerunits, productid.movementunitid]);
        });
    } else {
        callback && callback([])
    }
}

const ui = {
    fields: {
        warehouseidsrc : {name: 'warehouseidsrc', label:'Origen' , typeName: 'es.rbm.model.jpa.Warehouse', urlRecords: 'rest/warehouse/getActives'},
        warehouseidtarget : {name: 'warehouseidtarget', label:'Destino' , typeName: 'es.rbm.model.jpa.Warehouse', urlRecords: 'rest/warehouse/getActives'},
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product'},
        movementunitid : {name: 'movementunitid', label:'Unidad' , typeName: 'es.rbm.model.jpa.Movementunit', filterRecords: getUnits},
        //batchid : {name: 'batchid', label: 'Lote',typeName:'es.rbm.model.jpa.Batch', optional: true},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number'},
    }
}

export default function GenerateStockMovement(props){
    const {setOpen, open} = props;
    const [movement, setMovement] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) => {
        setMovement({...record});
        setComplete(complete);
    }

    const generateMovement = () =>{
        doPost("stock/generateMovementBetweenWarehouse", movement, () =>{
            setOpen(!open)
        })
    }

    useEffect(() =>{}, [])

    const onSuccessClick = (e) => {
        e.preventDefault();
        generateMovement()
    };

    return(
            <Box sx={{p:2, pt: 3}}>
                <SingleRecordForm ui={ui} records={movement} onChange={handledChange} />
                <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
            </Box>
    )
}
